import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { useWindowScroll, useWindowSize, useMount } from 'react-use'

import { motion } from 'framer-motion'
import ProgressiveImage from 'react-progressive-image'
import { parseACF, getImageCrop, truncateString } from '../utils'
import { Layout, Block, Slider, Section } from '../components'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../styles/global'
import { media } from '../styles/utils'

const Location = ({ pageContext, ...props }) => {
	const data = pageContext

	const { x, y } = useWindowScroll()
    const { width, height } = useWindowSize()
    const [activeIndex, setActiveIndex] = useState(-1)
    const [truncate, setTruncate] = useState(true)
	
	// Carousel

	const renderSlider = () => {
        if (!data?.intro?.carousel) return

        return (
            <SliderWrapper>
                <Slider
                    slides={data?.intro.carousel}
                    sliderStyles={sliderStyles}
                    hoverCursors
                />
            </SliderWrapper>
        )
    }

	// What We Offer

    const renderOffer = () => {
        return (
            <WhatWeOffer>
				<Title>{data?.offer?.heading}</Title>
				<Text
					dangerouslySetInnerHTML={{ __html: data?.offer?.introduction }}
				/>

                {renderClassItem(data?.offer?.classes)}
            </WhatWeOffer>
        )
    }

	// Classes

	const renderClassItem = (classes, image) => {
        if (!classes) return

        const items = classes.map((item, i) => {
            return (
                <ClassItem key={i} active={activeIndex == i}>
                    <Image
                        key={item.image?.sizes?.medium2}
                        src={item.image?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>

                    <Meta>
                        <Heading>{item.title}</Heading>
                        <SubHeading
                            dangerouslySetInnerHTML={{
                                __html: item.sub_heading,
                            }}
                        />

                        {activeIndex != i && (
                            <>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: truncateString(
                                            item.description.replace(
                                                /(<([^>]+)>)/gi,
                                                ''
                                            ),
                                            200
                                        ),
                                    }}
                                />

                                <Button
                                    onClick={() => {
                                        setTruncate(false)
                                        setActiveIndex(i)
                                    }}
                                >
                                    Read more
                                </Button>
                            </>
                        )}

                        {activeIndex == i && (
                            <>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: item.description,
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        setTruncate(true)
                                        setActiveIndex(null)
                                    }}
                                >
                                    Read less
                                </Button>
                            </>
                        )}
                    </Meta>
                </ClassItem>
            )
        })

        return <ClassList>{items}</ClassList>
    }

	// How to Join

    const renderHowToJoin = () => {
        return (
            <HowToJoin>
                <Title>{data?.join?.heading}</Title>
                <Text dangerouslySetInnerHTML={{ __html: data?.join?.introduction }} />

                <Section
                    className="section-join"
					layout={'join'}
					display={data?.new?.display}
					classes={data?.new?.classes}
					title={data?.new?.heading}
					text={data?.new?.introduction}
					link={data?.new?.link}
					linkText={data?.new?.link_text}
				/>
            </HowToJoin>
        )
    }

	// The Studio

	const renderTheStudio = () => {
		const items =
			data?.studio?.images &&
			data?.studio?.images.map((item, i) => {
				return (
					<ImageItem key={i}>
						<Image
							key={item.image?.sizes?.medium2}
							src={item.image?.sizes?.medium2}
						>
							{(src, loading) => {
								return (
									<BGImage
										image={src}
										style={{ opacity: loading ? 0 : 1 }}
									/>
								)
							}}
						</Image>
					</ImageItem>
				)
			})

		return (
			<TheStudio id="studio">
				<Title>{data?.studio?.heading}</Title>
				<Wrap>{items}</Wrap>
			</TheStudio>
		)
	}

	// Timetable

	const renderTimetable = () => {
		if(!data?.timetable?.display) return

		return (
			<Timetable id="timetable">
				<Title>{data?.timetable?.heading}</Title>

				{data?.timetable?.widget_embed && (
					<Widget dangerouslySetInnerHTML={{ __html: data?.timetable?.widget_embed }} />
				)}
			</Timetable>
		)
	}

    // Location

    const renderLocation = () => {
        if (!data?.location_display) return

        return (
            <LocationContent>
                <Wrap>
                    {data.title && <Title>{data?.title}</Title>}
                    {data.address && <Text>{data?.address}</Text>}
                    {data.location_description && <Text dangerouslySetInnerHTML={{ __html: data?.location_description }} />}
                </Wrap>
                {data?.location_image && (
                    <LocationImage>
                        <Image
                            key={data?.location_image?.sizes?.medium2}
                            src={data?.location_image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    </LocationImage>
                )}
            </LocationContent>
        )
    }

    return (
        <Layout meta={data?.seo}>
			<Container>
				{/* <Title>{pageContext.title}</Title>
				<Text dangerouslySetInnerHTML={{ __html: pageContext?.intro?.text }} /> */}
                {renderLocation()}
				{renderSlider()}
				{renderOffer()}
				{renderHowToJoin()}
                {renderTimetable()}
				{renderTheStudio()}
            </Container>
        </Layout>
    )
}

// Shared

const Title = styled.h1``
const Heading = styled.div``
const SubHeading = styled.div``
const Wrap = styled.div``
const ListItem = styled.div``
const Text = styled.div``
const Meta = styled.div``
const Button = styled.div``
const ImageItem = styled.div``
const LocationImage = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
	Margin-top: 200px;

	${Title} {
		${type.heading1};
		padding-bottom: 32px;
	}

	${Text} {
		${type.body};
		padding-bottom: 40px;
		max-width: 80%;

		${media.tablet`
			max-width: 100%;	
		`}
	}
`

// Intro

const Intro = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    ${media.tablet`
		flex-direction: column;	
		margin-bottom: 80px;
	`}

    ${Wrap} {
        flex-basis: calc(50% - 24px);

        ${Title} {
            ${type.heading1};
            padding-bottom: 32px;
        }
        ${Text} {
            ${type.body};
        }
        ${BGImage} {
            ${bgIcon};
            width: 100%;
            height: 100%;
            border-radius: 100%;
            min-height: 40vw;

            ${media.tablet`
				min-height: 382px;	
			`};
        }
    }
`

// Slider

const SliderWrapper = styled.div`
    margin-bottom: 150px;

    ${media.tablet`
        margin-bottom: 70px;
	`}
`

const sliderStyles = css`
    overflow: visible;
    height: 60vw;

    ${media.phone`
		height: 256px;
	`}

    .slide {
        background-color: ${colours.red};
    }

    .image-wrapper {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            // min-height: 25vw;
            object-fit: cover;
            object-position: center;

            ${media.phone`
				height: 256px;
			`}
        }
    }
`

// What We Offer

const WhatWeOffer = styled.div`
    margin-bottom: 150px;

    ${media.tablet`
        margin-bottom: 70px;
	`}

	${Wrap} {
		display: flex;
		flex-direction: column;
		max-width: 50%;
		margin-left: auto;

		${media.tablet`
			max-width: 100%;	
		`}
	}
`

// Classes

const Classes = styled.div`
    margin-bottom: 90px;

    ${media.tablet`
		margin-bottom: 40px;	
	`}

    ${Wrap} {
        ${Heading} {
            ${type.heading1};
            padding-bottom: 24px;
        }
        ${Text} {
            ${type.bodySmall};
            max-width: 80%;

            ${media.tablet`
				max-width: 100%;
			`}
        }
    }
`

const ClassList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ClassItem = styled.div`
    width: 100%;
    max-width: 915px;

    display: flex;
    background: ${colours.lightBeige};
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: 22px;

    padding: 38px;
    box-sizing: border-box;
    margin-bottom: 20px;

    ${media.tablet`
		flex-direction: column;	
		padding: 20px;
	`}

    ${(props) => {
        if (props.active == true)
            return css`
                background: ${colours.lightBlue};
            `
    }}

    &:hover {
        background: ${colours.lightBlue};
    }

    ${BGImage} {
        flex-basis: 50%;
        min-width: 280px;
        min-height: 238px;
        height: 100%;

        ${media.tablet`
			flex-basis: 100%;
			width: 172px;
			min-height: 142px;
			min-width: auto;
			margin-bottom: 20px;
		`}
    }

    ${Meta} {
        flex-basis: 100%;
        padding-left: 30px;

        ${media.tablet`
			width: 100%;
			padding-left: 0;
		`}

        ${Heading} {
            ${type.heading3};
            border-bottom: 3px solid ${colours.black};
            padding-bottom: 20px;
        }

        ${SubHeading} {
            font-size: 21px;
            padding: 20px 0;
        }

        ${Text} {
            ${type.bodySmall};

            p {
                ${type.bodySmall};
                margin: 0;
            }
        }

        ${Button} {
            ${type.bodySmall};
            text-decoration: underline;
            padding-top: 20px;
            cursor: pointer;
        }
    }
`

// How to Join

const HowToJoin = styled.div`
    margin-bottom: 150px;

    ${media.tablet`
        margin-bottom: 70px;
	`}

    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;	
		`}
    }

    ${Text} {
        ${type.body};
        max-width: 80%;

        ${media.tablet`
			max-width: 100%;	
		`}
    }
`

// The Studio

const TheStudio = styled.div`
    margin-bottom: 150px;

    ${media.tablet`
		margin-bottom: 70px;
	`}

    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;	
		`}
    }

    ${Wrap} {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: center;

        ${ImageItem} {
            padding-bottom: 72px;

            ${media.tablet`
				padding-bottom: 20px;
			`}

            &:nth-child(1) {
                width: 100%;

                ${BGImage} {
                    max-width: 913px;
                    min-height: 643px;
                    margin: 0 auto 0 30%;
                    ${bgIcon};

                    ${media.tablet`
                        min-height: 50vw;
					`}
                }
            }

            &:nth-child(2) {
                width: 45%;

                ${BGImage} {
                    max-width: 540px;
                    min-height: 670px;
                    ${bgIcon};

                    ${media.tablet`
                        min-height: 50vw;
					`}
                }
            }

            &:nth-child(3) {
                width: 45%;
                padding-top: 160px;

                ${media.tablet`
					width: 50%;
					padding-left: 20px;	
					padding-top: 60px;
				`}

                ${BGImage} {
                    max-width: 540px;
                    min-height: 670px;
                    border-radius: 100%;

                    ${media.tablet`
						min-height: 50vw;
					`}
                }
            }
        }
    }
`

// Timetable

const Timetable = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	${Title}, ${Text} {
		width: 100%;
        max-width: 100%;
	}
`

// Location

const LocationContent = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 40px;

    ${media.tablet`
        margin-bottom: 20px;
        flex-direction: column;
    `}

    ${Wrap}{
        display: flex;
        flex-direction: column;
        flex-basis: 50%;

        ${Title}{
            padding-bottom: 12px;
        }

        ${Text}{
            width: 100%;

            & a {
                text-decoration: underline;
                margin-bottom: 0px;
            }

            ${media.tablet`
                padding-bottom: 0;
            `}
        }

        .description p {
            ${type.bodySmall} !important;
        }
    }

    ${LocationImage}{
        flex-basis: 30%;

        ${BGImage} {
            min-width: 280px;
            min-height: 280px;
            height: 100%;
            aspect-ratio: 1/1;
    
            ${media.tablet`
                flex-basis: 100%;
                width: 172px;
                min-height: 142px;
                min-width: auto;
                margin-bottom: 20px;
            `}
        }
    }
`


// MindBody

const Widget = styled.div`
	min-height: 780px;
	max-width: 825px;
	margin: 0 0 60px;
	width: 100%;
	height: 100%;

	${media.tablet`
		margin-bottom: 20px;
		min-height: 534px;	
	`}
`

export default Location
